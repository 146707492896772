// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sQhc0DV6M", "H1VIXSuxf"];

const serializationHash = "framer-GRBHt"

const variantClassNames = {H1VIXSuxf: "framer-v-yw9nie", sQhc0DV6M: "framer-v-15877ua"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "sQhc0DV6M", "Variant 2": "H1VIXSuxf"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, Hwr1RuiJO: title ?? props.Hwr1RuiJO ?? "Get Started", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sQhc0DV6M"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Hwr1RuiJO, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sQhc0DV6M", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-15877ua", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sQhc0DV6M"} ref={ref ?? ref1} style={{backgroundColor: "rgb(26, 26, 26)", borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80, ...style}} {...addPropertyOverrides({H1VIXSuxf: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QUJDIERpYXR5cGUtNTAw", "--framer-font-family": "\"ABC Diatype\", \"ABC Diatype Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-line-height": "150%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(253, 249, 244))"}}>Get Started</motion.p></React.Fragment>} className={"framer-68yi5a"} data-framer-name={"CTA"} fonts={["GF;ABC Diatype-500"]} layoutDependency={layoutDependency} layoutId={"LF2FcI86U"} style={{"--extracted-r6o4lv": "rgb(253, 249, 244)"}} text={Hwr1RuiJO} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GRBHt.framer-11sab9y, .framer-GRBHt .framer-11sab9y { display: block; }", ".framer-GRBHt.framer-15877ua { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 18px 50px 18px 50px; position: relative; width: 327px; }", ".framer-GRBHt .framer-68yi5a { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GRBHt.framer-15877ua { gap: 0px; } .framer-GRBHt.framer-15877ua > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-GRBHt.framer-15877ua > :first-child { margin-left: 0px; } .framer-GRBHt.framer-15877ua > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 327
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"H1VIXSuxf":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Hwr1RuiJO":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpocJfUot9: React.ComponentType<Props> = withCSS(Component, css, "framer-GRBHt") as typeof Component;
export default FramerpocJfUot9;

FramerpocJfUot9.displayName = "Button";

FramerpocJfUot9.defaultProps = {height: 60, width: 327};

addPropertyControls(FramerpocJfUot9, {variant: {options: ["sQhc0DV6M", "H1VIXSuxf"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Hwr1RuiJO: {defaultValue: "Get Started", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerpocJfUot9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})